import * as React from "react"
import Heading from "../components/heading/Heading"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"

const CookiesPage = () => (
  <Layout className="blankPage">
    <Seo title="Polityka cookies" />
    <section className="container container--small">
      <div className="content">
        <Heading type="1" text='Polityka cookies' size="XL" />
        <ol>
          <li>Polityka plików „cookies” odnosi się do wszystkich witryn (w szczególności umieszczonych w domenie enel.pl), których właścicielem jest Centrum Medyczne ENEL-MED S.A., z siedzibą w Warszawie, ul. Słomińskiego 19 lok. 524, 00-195 Warszawa, NIP 5242593360, REGON 140802685, KRS 0000275255 (Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego), kapitał zakładowy 28&nbsp;280 279,00 zł, wpłacony w całości.</li>
          <li>Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach „cookies”.</li>
          <li>Pliki „cookies” to dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. „cookies” zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas&nbsp; przechowywania ich na urządzeniu końcowym oraz unikalny numer.</li>
          <li>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki „cookies” oraz uzyskującym do nich dostęp jest administrator Serwisu.</li>
          <li>Pliki „cookies” używane są do:<br />
            a. dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika; w szczególności pliki te pozwalają dostosować treść serwisu w zależności od indywidualnych potrzeb Użytkownika, np. ze względu na lokalizację.<br />
            b. tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co pozwala na ciągłe ulepszanie ich struktury i zawartości;</li>
          <li>W ramach Serwisu stosowane są następujące rodzaje plików „cookies”:<br />
            a. „funkcjonalne” pliki „cookies”, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;<br />
            b. „reklamowe” pliki „cookies”, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.</li>
          <li>Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików „cookies” na urządzeniu końcowym. Ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną obsługę plików „cookies” w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu na urządzenie użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</li>
          <li>Ograniczenie stosowania plików „cookies”, może wpłynąć na niektóre funkcjonalności dostępne na stronie internetowej.</li>
        </ol>
      </div>
    </section>
  </Layout>
)

export default CookiesPage
